//Banner Logo Right
.logo {
    opacity: 0;
    -webkit-animation:  opacity_effect 3s forwards;
    -o-animation:       opacity_effect 3s forwards;
    animation:          opacity_effect 3s forwards;
    animation-delay: 0s;
}
@keyframes opacity_effect {
    1% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;  
    }
    100% {
      opacity: 1;
    }
  }
  
//Banner Text Left
@import url('https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oswald:700');
.anim_title {
  width: 100%;

  display: flex;
  align-items: center;

  .anim_box {
    width: 100%;
    height: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .anim_title {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      height: 60%;

      .anim_block {
        width: 0%;
        height: inherit;
        background: #ffb510;
        position: absolute;
        animation: mainBlock 2s cubic-bezier(.74, .06, .4, .92) forwards;
        display: flex;
      }

      h1 {
        font-family: 'Oswald', sans-sherif;
        font-size: 3.5vw;
        color: #fff;
        -webkit-animation: mainFadeIn 2s forwards;
        -o-animation: mainFadeIn 2s forwards;
        animation: mainFadeIn 2s forwards;
        animation-delay: 1.6s;
        opacity: 0;
        display: flex;
        align-items: baseline;
        position: relative;

        span {
          width: 0px;
          height: 0px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;

          background: #ffb510;
          -webkit-animation: load 0.6s cubic-bezier(.74, .06, .4, .92) forwards;
          animation: popIn 0.8s cubic-bezier(.74, .06, .4, .92) forwards;
          animation-delay: 2s;
          margin-left: 30px;
          margin-top: 0px;
          position: absolute;
          bottom: -5px;
          right: -20px;

        }
      }
    }

    .anim_role {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      height: 60%;

      .anim_block {
        width: 0%;
        height: inherit;
        background: #e91e63;
        position: absolute;
        animation: secBlock 2s cubic-bezier(.74, .06, .4, .92) forwards;
        animation-delay: 2s;
        display: flex;
        margin-top: -2vh;
      }

      p {
        font-size: 1.2vw;
        animation: secFadeIn 2s forwards;
        animation-delay: 3.2s;
        opacity: 0;
        font-weight: 400;
        font-family: 'Lato';
        color: #ffffff;
        text-transform: uppercase;
        letter-spacing: 0.2vw;
      }
    }
  }
}
@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;

  }
  50% {
    width: 100%;
    left: 0;

  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;

  }
  50% {
    width: 100%;
    left: 0;

  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes popIn {
  0% {
    width: 0px;
    height: 0px;
    background: #e9d856;
    border: 0px solid #ddd;
    opacity: 0;
  }
  50% {
    width: 20px;
    height: 20px;
    background: #e9d856;
    opacity: 1;
    bottom: 55px;
  }
   65% {
      width: 17px;
    height: 17px;
      bottom: 0px;
      width: 25px
   }
   80% {
      width: 20px;
    height: 20px;
      bottom: 30px
   }
  100% {
    width: 17px;
    height: 17px;
    background: #e9d856;
    border: 0px solid #222;
    bottom: 10px;
    right: -30px;
  }
}
@keyframes secFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

//Comite Card
.card__comite {
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: Inter, sans-serif;
  }
  
  body {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
      background: #f5f5f5;
  }

  .demission {
    opacity: 0.5;
  }
  
  .wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 20px;
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
  }
  
  .card {
      position: relative;
      width: 325px;
      height: 450px;
      border-radius: 18px;
      overflow: hidden;
      box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  }
  
  .poster {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
  }
  
  .poster::before {
      content: '';
      position: absolute;
      bottom: -45%;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      transition: .3s;
  }
  
  .card:hover .poster::before {
      bottom: 0;
  }
  
  .poster img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: .3s;
  }
  
  .card:hover .poster img {
      transform: scale(1.1);
  }

  .details {
      position: absolute;
      bottom: -100%;
      left: 0;
      width: 100%;
      height: auto;
      padding: 1.5em 1.5em 2em;
      background: #000a;
      backdrop-filter: blur(16px) saturate(120%);
      transition: .3s;
      color: #fff;
      z-index: 0;
  }

  .contact {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 1.5em 1.5em 2em;
    background: #000a;
    backdrop-filter: blur(16px) saturate(120%);
    transition: 0s;
    color: #fff;
    z-index: 0;
  }

  .contact h1,
  .details h1,
  .contact h2,
  .details h2 {
      font-weight: 700;
  }

  .contact h1,
  .details h1 {
      font-size: 1.5em;
      margin-bottom: 5px;
  }

  .contact h2,
  .details h2 {
      font-weight: 400;
      font-size: 1em;
      margin-bottom: 10px;
      opacity: .6;
  }

  .contact .desc,
  .details .desc {
      color: #fff;
      opacity: .8;
      line-height: 1.5;
      margin-bottom: 1em;
  }

  .contact h3,
  .details h3 {
    font-weight: 400;
    font-size: 1em;
    margin-bottom: 10px;
    opacity: .6;
  }

  .contact h3 a,
  .details h3 a {
    color: #fff; /* Keep the text color white */
    text-decoration: underline; /* Add underline */
    font-weight: 400; /* Optional: Match the font weight */
    font-size: 1em; /* Optional: Match the font size */
  }

  .details .cast h3 {
      margin-bottom: .5em;
  }

  .contact .cast ul,
  .details .cast ul {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      gap: 0.625rem;
      width: 100%;
  }

  .contact .cast ul li,
  .details .cast ul li {
      list-style: none;
      width: 55px;
      border-radius: 50%;
      overflow: hidden;
  }

  .contact .cast ul li img,
  .details .cast ul li img {
      width: 100%;
      height: 100%;
  }

  .contact.visible,
  .details.visible {
    bottom: 0; // Fully visible
    height: auto; // Allow full height to show when visible
  }

  .contact.hidden,
  .details.hidden {
    bottom: -100;
    opacity: 0;
  }
}