.grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.grid-item {
    flex: 0 0 20%;
    margin: 20px;
    max-width: 40%;
}

.card-item {
    flex: 0 0 0;
    margin: 1px;
}

.content-item {
    margin: auto;
}