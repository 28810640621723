$color-main: #343a40;
$color-secondary: #52c4bb;
$color-secondary-dark: #3ba39b;
$banner-head: #2CCED2;
$palette-first: #FF5656;
$palette-seconde: #FFCC37;
$palette-third: #84C825;
$palette-four: #1875C3;
$palette-five: #6F4B92;
$grey: #4A4D57;
$normal: #15cda8;
$lightest: #dcdcdc;
$light: #6a39c6;
$color-white: #f6eeee;
$purple: #54123b;
$dark-red: #84142d;
$light-red: #c02739;
